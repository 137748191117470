import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Head from "../components/Head";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Service from "../components/Service";
import Instagram from "../components/InstagramEmbeded";
import Recruit from "../components/Recruit";
import News from "../components/News";
import queryString from "query-string";
import Menu from "../components/Menu";
import SecondView from "../components/SecondView";
import Map from "../components/Map";
import OfficeTokyoWorldSliderImage from "../components/OfficeTokyoWorldSliderImage";
import AOS from "aos";

const IndexPage = ({ location }) => {
  const data = useStaticQuery(
    graphql`
      query {
        view: file(relativePath: { eq: "secondView/second-view-3.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        sp_view: file(relativePath: { eq: "secondView/second-view-3-sp.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    `
  );
  const image = getImage(data.view);
  const spImage = getImage(data.sp_view);
  if (typeof document !== `undefined`) {
    console.log("find document");
    AOS.init({ duration: 800, easing: "ease-in-sine", delay: 300 });
  }

  return (
    <>
      <Head
        title="フォルカフェ東京ワールドゲート店 | forucafe"
        description="フォルカフェワールドゲート店です。forucafe東京ワールドゲートは、”ゲート”をイメージした木のあたたかみのある内装のオフィスの一角にあります。
大きな窓から眺める美しい景色とともに一杯一杯バリスタが丁寧に淹れるカフェラテはもちろんここだけ限定の、ほうじ茶ラテをお楽しみください。"
        url="https://forustyle-985bb.web.app/office-shibuya"
        imagePath="https://forustyle-985bb.web.app/ogp-tokyo-world.jpg"
      />
      <Header title="forucafe 東京ワールドゲート店" />
      <OfficeTokyoWorldSliderImage />
      <div data-aos="fade-in">
        <SecondView
          data={image}
          spData={spImage}
          text={`forucafe東京ワールドゲートは、
”ゲート”をイメージした木のあたたかみのある内装の
オフィスの一角にあります。
大きな窓から眺める美しい景色とともに
一杯一杯バリスタが丁寧に淹れるカフェラテはもちろん
ここだけ限定の、ほうじ茶ラテをお楽しみください。`}
          textTb={`forucafe東京ワールドゲートは、
”ゲート”をイメージした木のあたたかみのある内装の
オフィスの一角にあります。
大きな窓から眺める美しい景色とともに
一杯一杯バリスタが丁寧に淹れるカフェラテはもちろん
ここだけ限定の、ほうじ茶ラテをお楽しみください。`}
          textSp={`forucafe東京ワールドゲートは、
”ゲート”をイメージした木の
あたたかみのある内装の
オフィスの一角にあります。
大きな窓から眺める
美しい景色とともに
一杯一杯バリスタが丁寧に
淹れるカフェラテはもちろん
ここだけ限定の、ほうじ茶ラテを
お楽しみください。`}
        />
      </div>
      <div data-aos="fade-in">
        <Menu shop="東京ワールドゲート店" isDetail={false} tokyo />
      </div>
      <div data-aos="fade-in">
        <Map
          title={`forucafe東京ワールドゲート店`}
          textUnderTheTitle={`※WeWorkメンバーのみご利用いただけます。`}
          post={`〒1050001 東京都港区虎ノ門4-1-1 23F
WeWork神谷町トラストタワー
23F,4-1-1,Toranomon,Minato-ku,Tokyo`}
          nearestStation={0}
          phoneNumber={0}
          isWifi={0}
          isPowerSupply={0}
          time={`9:00~17:30`}
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12965.890533888796!2d139.7451346!3d35.6653627!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4e5cc4665b32a28c!2zV2VXb3JrIOelnuiwt-eUuuODiOODqeOCueODiOOCv-ODr-ODvA!5e0!3m2!1sja!2sjp!4v1645793750953!5m2!1sja!2sjp"
        />
      </div>
      <div data-aos="fade-in">
        <News shop="東京ワールドゲート店" />
      </div>
      <div data-aos="fade-in">
        <Service />
      </div>
      <div data-aos="fade-in">
        <Recruit />
      </div>
      <div data-aos="fade-in">
        <Footer />
      </div>
    </>
  );
};

export default IndexPage;
